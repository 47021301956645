import { memo, useState } from 'react';
import { useForm } from "react-hook-form";
import SvgButton from '../../components/ui/SvgButton';
import { postEnquiry } from '../../utils/api';
import './technical-support.scss';

interface PageSettingsTechnicalSupportProps {
  onClose: () => void
}

function PageSettingsTechnicalSupport(props: PageSettingsTechnicalSupportProps) {
  const [categoryShow, setCategoryShow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    setButtonDisabled(true);
    if (data.email && data.category && data.details) {
      // console.log(data);
      postEnquiry(data).then((e) => {
        // console.log(e);
        setButtonDisabled(false);
        setValue('email', '');
        setValue('category', '');
        setValue('details', '');

        alert('信息發送成功');
      });
    } else {
      setButtonDisabled(false);
      alert('請輪入全部資料');
    }
  };

  const onCategoryClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setCategoryShow(o => !o);
  }

  const onCategorySelect = (v: string) => {
    setValue('category', v);
    setCategoryShow(false);
  }

  return (
    <div className="page-settings-technical-support dialog" style={{ backgroundImage: "url(/assets/settings/technical-support/setting-support-bubble.svg" }}>
      <h3 className="dialog-title">技術支援</h3>
      <button className="dialog-btn-close" aria-label="關閉" onClick={() => props.onClose()}><SvgButton prefix="/assets/settings/" src1="setting-button-close.svg" src2="setting-button-close-select.svg" alt="關閉"></SvgButton></button>
      <form onSubmit={handleSubmit(onSubmit)} aira-label="技術支援表單">
        <div className="form">
          <div className="input-group">
            <div className="input-label"><label aria-label="聯絡方法">聯絡方法 *：</label></div>
            <div className="input-field" style={{ backgroundImage: "url(/assets/settings/technical-support/setting-box-1.svg)" }}>
              <input {...register("email")} className={errors.email ? "error" : ''} placeholder="聯絡方法" aria-label="聯絡方法" />
            </div>
          </div>
          <div className="input-group">
            <div className="input-label"><label aria-label="技術支援類別">技術支援類別：</label></div>
            <div className="input-field input-field-select" style={{ backgroundImage: "url(/assets/settings/technical-support/setting-box-1.svg)" }}>
              <input onClick={onCategoryClick} {...register("category")} placeholder="技術支援類別" aria-label="技術支援類別" />
              <button type="button" onClick={onCategoryClick}><SvgButton prefix="/assets/settings/technical-support/" src1="setting-support-button-arrow.svg" src2="setting-support-button-arrow-select.svg" alt="回到主頁"></SvgButton></button>
              <div className={`input-field-select-options${categoryShow ? ' input-field-select-options-on' : ''}`} style={{ backgroundImage: "url(/assets/settings/technical-support/setting-scrolldown.svg)", "--option-bg": "url(/assets/settings/technical-support/setting-select.svg)" } as React.CSSProperties}>
                <div onClick={() => onCategorySelect('畫面異常')} className="input-field-select-option"><span>畫面異常</span></div>
                <div onClick={() => onCategorySelect('計分紀錄異常')} className="input-field-select-option"><span>計分紀錄異常</span></div>
                <div onClick={() => onCategorySelect('其他')} className="input-field-select-option"><span>其他</span></div>
              </div>
            </div>
          </div>
          <div className="input-group">
            <div className="input-label"><label aria-label="詳細內容">詳細內容：</label></div>
            <div className="input-field input-field-textarea" style={{ backgroundImage: "url(/assets/settings/technical-support/setting-box-2.svg)" }}>
              <textarea {...register("details")} placeholder="詳細內容" aria-label="詳細內容"></textarea>
            </div>
          </div>

          <button className="page-settings-technical-support-submit" type="submit" aria-label="提交" disabled={buttonDisabled}>
            <SvgButton prefix="/assets/settings/technical-support/" src1="course1-button-submit.svg" src2="course1-button-submit-mouseover.svg" alt="提交" />
          </button>
        </div>
      </form>
    </div>
  )
}

export default memo(PageSettingsTechnicalSupport);
